import { Controller } from "stimulus"
import { initAttachmentGenerationCable } from "../channels/attachment_generation_channel"

export default class extends Controller {
  static targets = []

  connect() {
    initAttachmentGenerationCable(this.attachmentId)
  }

  get attachmentId() {
    return this.element.dataset.attachmentId
  }
}
