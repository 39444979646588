import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["instance_variable"];

  connect() {
    const modelClass = this.titleize(this.instance_variableTarget.dataset.installType);
    const projectId = this.instance_variableTarget.dataset.projectId
    const installScopeId = this.instance_variableTarget.dataset.installScopeId

    if ($('.data-table').data('url')) {
      $('.data-table').on('load-success.bs.table', function () {
        $('.get-value-project-manager').each(function() {
          $(this).closest('td').addClass('editable editable-project-manager')
        });
        $('.get-value-installer').each(function() {
          $(this).closest('td').addClass('editable editable-installer')
        });
      });
    } else {
      $('.get-value-project-manager').each(function() {
        $(this).closest('td').addClass('editable editable-project-manager')
      });
      $('.get-value-installer').each(function() {
        $(this).closest('td').addClass('editable editable-installer')
      });
    }

    $('.data-table').on('click-cell.bs.table', function editCount(event, field, value, row, $element){
      if ($element.hasClass("editable-project-manager") && !$element.find('.simple_form').length) {
        const modelId = $element.find('.get-value-project-manager').data('team-member').replace("team-member-project-manager-", "")
        const teamMemberType = $element.find('.get-value-project-manager').data('team-member-type')
        const data = {
          'model_id': modelId,
          'model_class': modelClass,
          'team_member_type': teamMemberType
        }

        $.ajax({
          type: 'GET',
          url: "/projects/" + projectId + "/scopes/" + installScopeId + "/install_inline_team_members/new",
          data: data,
        })
      }

      if ($element.hasClass("editable-installer") && !$element.find('.simple_form').length) {
        const modelId = $element.find('.get-value-installer').data('team-member').replace("team-member-installer-", "")
        const teamMemberType = $element.find('.get-value-installer').data('team-member-type')
        const data = {
          'model_id': modelId,
          'model_class': modelClass,
          'team_member_type': teamMemberType
        }

        $.ajax({
          type: 'GET',
          url: "/projects/" + projectId + "/scopes/" + installScopeId + "/install_inline_team_members/new",
          data: data,
        })
      }
    });
  }

  cancelSubmit(e) {
    let target = e.currentTarget
    let selectId = target.dataset['selectElement']
    let formId = target.dataset['formElement']
    let existingValue = target.dataset['existingValue']

    let $select = $(selectId)
    $select.val(existingValue)
    $select.trigger('change')
    $(formId).submit()
  }

  titleize(sentence) {
    let split = sentence.split("_");
    let outArr = []
    for (let i = 0; i < split.length; i++) {
      const word = split[i];
      const capitalize = word.charAt(0).toUpperCase() + word.slice(1)
      outArr.push(capitalize)
    }

    return outArr.join('')
  }
}
