import { Controller } from "stimulus";

export default class extends Controller {

  showVariants(e) {
    const targetElement = e.currentTarget;
    const productId = targetElement.dataset.productId;
    const optionValues = targetElement.dataset.optionValues;
    const insertElement = document.getElementById(`product_${productId}_variants_inject`);

    if (insertElement.childNodes.length == 0) {
      insertElement.innerHTML = `<div class='text-center'><h1 class='mb-0'><i class="fa fa-cog fa-spin"></i></h1></div>`;
      insertElement.classList.remove('hidden');
      $.ajax({
        type: 'GET',
        url: "/products/" + productId + "/variants",
        data: { option_values: optionValues }
      })
    } else {
      if (insertElement.classList.contains('hidden')) {
        insertElement.classList.remove('hidden');
      } else {
        insertElement.classList.add('hidden');
      }
    }
  }

}
