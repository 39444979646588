import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'instance_variable' ];

  connect() {
    var installType = this.instance_variableTarget.dataset.installType

    if ($('.data-table').data('url')) {
      $('.data-table').on('load-success.bs.table', function () {
        $('.get-value-date').each(function() {
          $(this).closest('td').addClass('editable editable-date')
        });
      });
    } else {
      $('.get-value-date').each(function() {
        $(this).closest('td').addClass('editable editable-date')
      });
    }

    $('.data-table').on('click-cell.bs.table', function editCount(event, field, value, row, $element){
      if ($element.hasClass("editable-date")) {
        var modelId = row._id.replace(installType+ "_", "")
        var inputId = field + "_" + modelId
        const innerValue = $element.find(".get-value-date").html()

        if (!$element.find('#'+inputId).val()) { //find.val prevents additional clicks in cell to refresh input field.
          var insertInput = `
            <div class="input-group inline-edit" style='min-width: 210px;'=>
              <input id="${inputId}" class="string optional form-control" type="text" value="${innerValue}"/>
              <div class="input-group-append">
                <button data-action='click->start-finishes#submitDate' data-model-id='${modelId}' data-input-id='${inputId}' data-existing-value='${null}' data-attribute='${field}' class="btn inline-btn btn-success"><i class="fa fa-check"></i></button>
                <button data-action='click->start-finishes#submitDate' data-model-id='${modelId}' data-input-id='${inputId}' data-existing-value='${innerValue}' data-attribute='${field}' class="btn inline-btn btn-secondary"><i class="fa fa-times"></i></button>
              </div>
            </div>`

          $($element).html(insertInput);
          $('#'+inputId).datepicker({ dateFormat: 'm/dd/yy' })
          RL.putCursorAtEndOfInput($('#'+inputId))
        }
      }
    });
  }

  submitDate(e) {
    var installType = this.instance_variableTarget.dataset.installType
    var installTypeCamel = this.titleize(this.instance_variableTarget.dataset.installType)

    let target = e.currentTarget
    let modelId = target.dataset['modelId']
    let inputId = target.dataset['inputId']
    let existingValue = target.dataset['existingValue']
    let attribute = target.dataset['attribute']

    let row = document.getElementById(installType+ "_"+ modelId)
    let index = row.dataset.index

    let newValue = existingValue == 'null' ? $('#'+inputId).val() : existingValue;
    let timestamp = Date.parse(newValue);

    // confirms it is a legit date
    if (isNaN(timestamp) == false) {
      let date = new Date(timestamp);
      let data = {
        model_id: modelId,
        model_class: installTypeCamel,
        field: attribute,
        value: date
      }

      $.ajax({
        type: 'PUT',
        url: `/projects/${this.instance_variableTarget.dataset.projectId}/scopes/${this.instance_variableTarget.dataset.installScopeId}/install_start_finish`,
        data: data,
        dataType: 'json',
        success: function(result) {
          const newCell = `<div class="get-value-date">${result.time}</div>`
          $('#'+inputId).closest('td.editable').html(newCell)
          $('.data-table').bootstrapTable('updateCell', { index: index, field: attribute, value: newCell, reinit: false })
        }
      })

    } else {
      const newCell = `<div class="get-value-date">${newValue}</div>`
      $('#'+inputId).closest('td.editable').html(newCell)
      $('.data-table').bootstrapTable('updateCell', { index: index, field: attribute, value: newCell, reinit: true })
    }
  }

  titleize(sentence) {
    let split = sentence.split("_");
    let outArr = []
    for (let i = 0; i < split.length; i++) {
      const word = split[i];
      const capitalize = word.charAt(0).toUpperCase() + word.slice(1)
      outArr.push(capitalize)
    }

    return outArr.join('')
  }
}
