import { Controller } from "stimulus";

export default class extends Controller {

  headerNav(e) {
    const parentId = e.currentTarget.dataset.parentId
    const level = e.currentTarget.dataset.level
    const dropdownElement = document.getElementById(`header-children-${parentId}`)

    if (dropdownElement.innerHTML.includes('fa-cog fa-spin')) { 
      $.ajax({
        type: 'GET',
        url: "/product_categories/" + parentId + "/children",
        data: { level: level }
      })
    }
  }

  nestedChild(e) {
    e.stopPropagation();
    const targetElement = e.currentTarget;
    const parentId = targetElement.dataset.parentId;
    const level = targetElement.dataset.level;
    const insertElement = document.getElementById(`nested-child-${parentId}`);

    if (insertElement.childNodes.length == 0) {
      targetElement.innerHTML = `<i class="fa fa-cog fa-spin"></i>`;
      insertElement.classList.remove('hidden');
      $.ajax({
        type: 'GET',
        url: "/product_categories/" + parentId + "/nested_children",
        data: { level: level }
      }).done( function() {
        targetElement.innerHTML = `<i class="fa fa-caret-down"></i>`;
      })
    } else {
      if (insertElement.classList.contains('hidden')) {
        targetElement.innerHTML = `<i class="fa fa-caret-down"></i>`;
        insertElement.classList.remove('hidden');
      } else {
        targetElement.innerHTML = `<i class="fa fa-caret-right"></i>`;
        insertElement.classList.add('hidden');
      }
    }
  }

}
