import { Controller } from "stimulus";
import { number_to_currency } from '../helpers/numberHelper'

export default class extends Controller {
  static targets = ['total', 'total_purchase_price'];

  updateQuantity(e) {
    const id = e.currentTarget.id
    const priceId = id.replace('quantity', 'price');
    const totalId = id.replace('quantity', 'total');

    const newTotal = e.currentTarget.value * document.getElementById(priceId).value

    document.getElementById(totalId).value = newTotal

    // below line could be better, but works
    const position = parseInt(id.replace('order_', '').replace('_order_items_attributes', '').replace('_quantity', '').split('_')[1])
    this.totalTargets[position].innerHTML = number_to_currency(newTotal, 2)

    let totalPurchasePrice = 0;
    for (let i = 0; i < this.totalTargets.length; i++) {
      const totalColumn = this.totalTargets[i];
      totalPurchasePrice += parseFloat(totalColumn.innerHTML.replace(/[^-0-9.]/g, ''))
    }

    this.total_purchase_priceTarget.innerHTML = number_to_currency(totalPurchasePrice, 2)
  }

}
