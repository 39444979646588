import consumer from "./consumer"

const initAttachmentGenerationCable = (id) => {
  const params = { channel: "AttachmentGenerationChannel", id: id }
  const attachmentCard = document.querySelector(`.card[data-attachment-id='${id}']`)

  const cableCallbacks = {
    // Called once when the subscription is created.
    initialized() {},

    // Called when the subscription is ready for use on the server.
    connected() {},

    // Called when the WebSocket connection is closed.
    disconnected() {},

    // Called when the subscription is rejected by the server.
    rejected() {},

    // Called when a broadcast is received
    received(data) {
      attachmentCard.outerHTML = data.template
    },
  }

  consumer.subscriptions.create(params, cableCallbacks);
}

export { initAttachmentGenerationCable };
