import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'schedule' ];

  connect() {
    let optionString = "Use default"
    const scheduleName = this.scheduleTarget.dataset.name
    const scheduleDetails = this.scheduleTarget.dataset.details
    const scheduleUnit = this.scheduleTarget.dataset.unit

    if (scheduleName) {
      optionString += `: ${scheduleName}`
    }

    if (scheduleDetails) {
      if (scheduleUnit == 'per kWh') {
        optionString += ` ($${scheduleDetails} ${scheduleUnit})`
      } else {
        optionString += ` (${scheduleDetails} ${scheduleUnit})`
      }
    }

    const option = new Option(optionString, 'inherit') // in controller it converts 'inherit' to '' check locations_controller#update
    this.scheduleTarget.prepend(option)

    if (this.scheduleTarget.value == '') {
      this.scheduleTarget.value = 'inherit'
    }
  }
}
