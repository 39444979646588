import { Controller } from "stimulus";

export default class extends Controller {
  static values = { used: Array }

  existingRetroluxSystem(e) {
    let target = e.currentTarget
    let regexedValue = target.value.replaceAll(/[^0-9a-zA-Z ]/ig, '').replaceAll(/( )/g, '_').toLowerCase()
    let submitButton = document.getElementById('submit-button')

    if (this.usedValue.includes(regexedValue)) {
      submitButton.disabled = true
      if (e.which == '13') {
        e.preventDefault();
      }
      document.getElementById('error-block').innerHTML = `<span class="help-block">There is an existing Retrolux System with the internal name ${regexedValue}</span>`
    } else {
      submitButton.disabled = false
      document.getElementById('error-block').innerHTML = ``
    }
  }

}
