import { Controller } from "stimulus";

export default class extends Controller {

  removeFilter(e) {
    e.currentTarget.remove();
    const optionValueId = e.currentTarget.dataset.optionValueId;
    const vendorId = e.currentTarget.dataset.vendorId;
    const manufacturerId = e.currentTarget.dataset.manufacturerId;

    let matchingCheckbox;
    // These matching checkboxes are in taxons/_filter_swipeout.haml
    if (optionValueId) {
      matchingCheckbox = document.querySelector(`input[type='checkbox'][id='variant_filter_option_values_${optionValueId}']`);
    } else if (vendorId) {
      matchingCheckbox = document.querySelector(`input[type='checkbox'][id='variant_filter_vendor_ids_${vendorId}']`);
    } else if (manufacturerId) {
      matchingCheckbox = document.querySelector(`input[type='checkbox'][id='variant_filter_manufacturer_ids_${manufacturerId}']`);
    }

    if (matchingCheckbox) { matchingCheckbox.checked = false }
    document.getElementById('new_variant_filter').submit();
  }

  addOrRemoveCheckbox(e) {
    const optionValueId = e.currentTarget.dataset.optionValueId;
    const vendorId = e.currentTarget.dataset.vendorId;
    const manufacturerId = e.currentTarget.dataset.manufacturerId;
    const selected = e.currentTarget.dataset.selected;

    let matchingCheckbox;
    // These matching checkboxes are in taxons/_filter_swipeout.haml
    if (optionValueId) {
      matchingCheckbox = document.querySelector(`input[type='checkbox'][id='variant_filter_option_values_${optionValueId}']`);
    } else if (vendorId) {
      matchingCheckbox = document.querySelector(`input[type='checkbox'][id='variant_filter_vendor_ids_${vendorId}']`);
    } else if (manufacturerId) {
      matchingCheckbox = document.querySelector(`input[type='checkbox'][id='variant_filter_manufacturer_ids_${manufacturerId}']`);
    }

    matchingCheckbox.checked = selected == 'true' ? false : true;
    document.getElementById('new_variant_filter').submit();
  }

  changeViewStye(e) {
    const viewStyle = e.currentTarget.dataset.viewStyle;
    const currentViewStyle = e.currentTarget.dataset.currentViewStyle;
    if (viewStyle == currentViewStyle) { return; }

    document.getElementById('variant_filter_view_style').value = viewStyle;;
    document.getElementById('new_variant_filter').submit();
  }

  search(e) {
    let currentTarget = e.currentTarget;

    if (currentTarget.value.length >= 2) {
      setTimeout(function() { 
        $.ajax({
          type: 'GET',
          url: "/product_categories/" + currentTarget.dataset.taxonId + "/search",
          data: { 
            search_value: currentTarget.value,
            product_ids: currentTarget.dataset.productIds
          }
        })
      }, 300)
    } else {
      document.getElementById('search-results').classList.add('hidden');
    }
  }
}
