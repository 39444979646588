import { Controller } from "stimulus";

export default class extends Controller {

  showHideOption(e) {
    const optionTypeId = e.currentTarget.dataset.optionTypeId;
    const checkboxesElement = optionTypeId ? document.getElementById(`filter_options_${optionTypeId}`) : document.getElementById(`vendors_filter`);

    if (e.currentTarget.innerHTML == `<i class="fa fa-minus"></i>`) {
      e.currentTarget.innerHTML = `<i class="fa fa-plus"></i>`;
      checkboxesElement.classList.add('hidden');
    } else {
      e.currentTarget.innerHTML = `<i class="fa fa-minus"></i>`;
      checkboxesElement.classList.remove('hidden');
    }
  }

  showMoreOptions(e) {
    const optionTypeId = e.currentTarget.dataset.optionTypeId;
    const hiddenOptionsSize = e.currentTarget.dataset.hiddenOptionsSize;
    const moreOptionsElement = document.getElementById(`more_option_values_${optionTypeId}`);

    if (moreOptionsElement.classList.contains('hidden')) {
      e.currentTarget.innerText = `Hide`
      moreOptionsElement.classList.remove('hidden');
    } else {
      e.currentTarget.innerText = `Show ${hiddenOptionsSize} more`
      moreOptionsElement.classList.add('hidden');
    }
  }

  searchFilterValues(e) {
    const currentTarget = e.currentTarget;
    const optionTypeId = currentTarget.dataset.optionTypeId;
    const selectedOptionValueIds = currentTarget.dataset.selectedOptionValueIds;
    const variantIds = currentTarget.dataset.variantIds;

    document.getElementById(`filter_options_${optionTypeId}`).innerHTML = `<div class='text-center'><h1 class='mb-0'><i class="fa fa-cog fa-spin"></i></h1></div>`;

    $.ajax({
      type: 'POST',
      url: `/option_types/${optionTypeId}/search_option_values`,
      data: { 
        search: currentTarget.value,
        selected_option_value_ids: selectedOptionValueIds,
        variant_ids: variantIds
      }
    })
  }
}
