import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['input', 'installed_quantity', 'remaining_quantity', 'total_quantity', 'complete'];

  updateRatio(e){
    const url = e.currentTarget.dataset.url;
    const input = this.inputTarget;
    const value = e.currentTarget.dataset.replacementCount ? e.currentTarget.dataset.replacementCount : input.value;
    const installedTargets = this.installed_quantityTargets
    const remainingTargets = this.remaining_quantityTargets
    const totalTargets = this.total_quantityTargets

    $.ajax({
      type: 'PUT',
      url: url,
      data: {
        'install_measure': { 'installed_ratio': value }
      },
      dataType: 'json',
      success: function(result) {

        if (result.success) {
          input.value = value;
          if (result.quantity_array) {
            for (let i = 0; i < result.quantity_array.length; i++) {
              const quantityObject = result.quantity_array[i];
              installedTargets[i].innerHTML = quantityObject['installed_quantity']
              remainingTargets[i].innerHTML = quantityObject['remaining_quantity']
              totalTargets[i].innerHTML = quantityObject['total_quantity']
            }
          }
        }

      }
    })

  }

  addRemoveCompleteCss(navId, didComplete) {
    const complete = this.completeTarget;
    const navElement = document.getElementById(navId);

    if (didComplete) {
      complete.classList.add("bg-complete");
      if (navElement) { navElement.classList.add("bg-complete"); }
    } else {
      complete.classList.remove("bg-complete");
      if (navElement) { navElement.classList.remove("bg-complete"); }
    }
  }
}
