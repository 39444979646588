import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    const elementTarget = this.element;
    const variantIds = elementTarget.dataset.variantIds;
    const optionValueIds = elementTarget.dataset.optionValueIds;

    $.ajax({
      type: 'POST',
      url: "/variants_size",
      data: { 
        variant_ids: variantIds,
        option_value_ids: optionValueIds
      }
    }).done( function( data ) {

      for (const key in data['option_values']) {
        const variantSizeString = data['option_values'][key];
        const injectElements = document.querySelectorAll(`[data-variant-size="option-value-${key}"]`);

        for (let i = 0; i < injectElements.length; i++) {
          const injectElement = injectElements[i];
          if (injectElement != null) {
            injectElement.innerText = variantSizeString;
          }
        }
      }

      for (const key in data['manufacturers']) {
        const variantSizeString = data['manufacturers'][key];
        const injectElements = document.querySelectorAll(`[data-variant-size="manufacturer-${key}"]`);

        for (let i = 0; i < injectElements.length; i++) {
          const injectElement = injectElements[i];
          if (injectElement != null) {
            injectElement.innerText = variantSizeString;
          }
        }
      }

      for (const key in data['vendors']) {
        const variantSizeString = data['vendors'][key];
        const injectElements = document.querySelectorAll(`[data-variant-size="vendor-${key}"]`);
        for (let i = 0; i < injectElements.length; i++) {
          const injectElement = injectElements[i];
          if (injectElement != null) {
            injectElement.innerText = variantSizeString;
          }
        }
      }
    })
  }

}
