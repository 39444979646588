import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    const $modal = $(this.modalTarget)
    $modal.modal({ show: false });

    // this.element.addEventListener('touchstart', handleTouchStart, false);
    // this.element.addEventListener('touchmove', handleTouchMove, false);

    // var xDown = null;
    // var yDown = null;

    // function getTouches(evt) {
    //   return evt.touches ||             // browser API
    //         evt.originalEvent.touches; // jQuery
    // }

    // function handleTouchStart(evt) {
    //     const firstTouch = getTouches(evt)[0];
    //     xDown = firstTouch.clientX;
    //     yDown = firstTouch.clientY;
    // };

    // function handleTouchMove(evt) {
    //     if ( ! xDown || ! yDown ) {
    //         return;
    //     }

    //     var xUp = evt.touches[0].clientX;
    //     var yUp = evt.touches[0].clientY;

    //     var xDiff = xDown - xUp;
    //     var yDiff = yDown - yUp;

    //     if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
    //         if ( xDiff > 0 ) {
    //           $modal.modal("hide");
    //             /* left swipe */
    //         } else {
    //             /* right swipe */
    //         }
    //     } else {
    //         if ( yDiff > 0 ) {
    //             /* up swipe */
    //         } else {
    //             /* down swipe */
    //         }
    //     }
    //     /* reset values */
    //     xDown = null;
    //     yDown = null;
    // };
  }

  goToPath(e){
    $(this.modalTarget).modal("dispose");
    window.location = e.currentTarget.dataset.path;
  }

  disconnect() {
    // this.element.removeEventListener('touchstart', handleTouchStart, false);
    // this.element.removeEventListener('touchmove', handleTouchMove, false);
  }
}
