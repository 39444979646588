import { Controller } from "stimulus";
import { round_to } from '../helpers/numberHelper'

export default class extends Controller {
  static targets = ['facility_hours_per_day_annual_average', 'facility_days_per_week_annual_average', 'openings_per_hour_annual_average'];

  connect() {
    this.months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  }


  updateAnnualAverage(e) {
    const id = e.currentTarget.id
    const columnName = e.currentTarget.dataset.columnName
    const min = e.currentTarget.dataset.min
    const max = e.currentTarget.dataset.max

    if (parseFloat(e.currentTarget.value, 10) > max) {
      document.getElementById(id).value = max;
    }
    if (parseFloat(e.currentTarget.value, 10) < min) {
      document.getElementById(id).value = min;
    }
    
    let current_field = 'door_operating_schedule_' + columnName + "_" // door_operating_schedule_facility_hours_per_day_

    let total = 0
    for (let i = 0; i < this.months.length; i++) {
      const month = this.months[i];
      const existingValue = parseFloat(document.getElementById(current_field + month).value || '0', 10)

      total += existingValue
    }

    switch(columnName) {
      case 'facility_hours_per_day':
        this.facility_hours_per_day_annual_averageTarget.innerHTML = round_to(total / 12, 2)
        break;
      case 'facility_days_per_week':
        this.facility_days_per_week_annual_averageTarget.innerHTML = round_to(total / 12, 2)
        break;
      case 'openings_per_hour':
        this.openings_per_hour_annual_averageTarget.innerHTML = round_to(total / 12, 2)
        break;
    }
  }

}
