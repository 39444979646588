import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [];

  connect() {
    this.tableId = `#${this.element.id}`

    $(this.tableId).bootstrapTable({
      onLoadSuccess: function(data) {
        $(".not-complete").each(function() {
          $(this).closest("td").addClass('not-complete-background')
        })
        $(".no-pointer-or-hover").each(function() {
          $(this).closest("td").removeClass('pointer')
          $(this).closest("td").addClass('no-hover')
        })
      }
    });

    const tableIdForFullWidthSearch = this.tableId;
    if ($(".search").length) {
      $(".search").each(function() {
        $(this).addClass('pull-left')

        if ($(this).find(".search-icon").length == 0) {
          $(this).append('<div onclick="RL.tableSearch(event)" class="search-icon"><i class="fa fa-search"></i><p class="search-text">search</p></div>');
          if ($(tableIdForFullWidthSearch).hasClass('full-width-search')) {
            $(this).addClass('w-100')
          }
        }

      })
    }

    if ($(".keep-open").length) {
      $(".keep-open").each(function() {
        $(this).height(200);
      });
    }

    if ($(".export").length) {
      $(".export").each(function() {
        $(this).height(200);
      });
    }

    // Add .no-bootstrap-toolbar class to table to hide it. Currently not used.
    $('.no-bootstrap-toolbar').find('div.fixed-table-toolbar').hide();

    const dataObject = $(this.tableId).data()
    if (dataObject.hasOwnProperty('bulkActions')) { // needs 'data-bulk-actions': 'true'
      const $selector = "#existing-lightings-list"
      $($selector).on('check.bs.table uncheck.bs.table check-all.bs.table uncheck-all.bs.table', function () {
        const length = $(this.tableId).bootstrapTable('getSelections').length
        if (length > 0) {
          $(".bulk-actions").show("slide", { direction: "left" }, 100);
        } else {
          $(".bulk-actions").hide("slide", { direction: "left" }, 100);
        }
      })
    }
  }

}