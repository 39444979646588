import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  toggle(e) {
    const target = e.currentTarget

    let toggleData = {}
    toggleData[target.dataset['type']] = { use: target.dataset['use'] }

    $.ajax({
      type: 'put',
      url: target.dataset['url'],
      data: toggleData,
      dataType: 'json',
      success: function(result) {
        if (result.success) {
          if (result.ids) {
            for (let i = 0; i < result.ids.length; i++) {
              const element = document.querySelector(`[data-id="${result.ids[i]}"]`);
              if (!element) { continue }
              element.dataset['use'] = result.use === true ? 'false' : 'true'
              element.innerHTML = result.use === true ? 'on' : 'off'
              if (result.use === true) {
                element.classList.add("btn-success");
                element.classList.remove("btn-secondary");
              } else {
                element.classList.remove("half-use-button");
                element.classList.remove("btn-success");
                element.classList.add("btn-secondary");
              }
            }
          }
        }
      }
    })
  }
}
