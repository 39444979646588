import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    $('.data-table').on('click-cell.bs.table', function editCount(event, field, value, row, $element){
      if ($element.hasClass("editable") && !$element.find('.simple_form').length) {

        const data = {
          'model_id': row['_data'].model_id,
          'model_class': row['_data'].model_class
        }

        $.ajax({
          type: 'GET',
          url: "/projects/" + row['_data'].project_id + "/inline_operating_schedule/new",
          data: data,
        })
      }
    });
  }

  cancelSubmit(e) {
    let target = e.currentTarget
    let selectId = target.dataset['selectElement']
    let existingValue = target.dataset['existingValue']

    let $select = $(`#${selectId}`)
    $select.val(existingValue)
    $select.trigger('change')
    $select.closest('form').submit()
  }
}
