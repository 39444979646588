import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ['tab', 'link' ];

  connect() {
    for (let i = 0; i < this.tabTargets.length; i++) {
      const tab = this.tabTargets[i];

      if (!tab.classList.contains('hidden') && tab.dataset['url']) {
        $.ajax({
          type: 'GET',
          url: tab.dataset['url']
        })
      }
    }
  }

  activateTab(e) {
    const activeIndex = e.currentTarget.dataset.tab - 1

    for (let i = 0; i < this.tabTargets.length; i++) {
      const tab = this.tabTargets[i];
      const link = this.linkTargets[i];

      if (i == activeIndex) {
        tab.classList.remove("hidden");
        // if table is already present don't re-GET ajax
        if (tab.dataset['url'] && !$(tab.dataset['tablePresentId']).length) {
          $.ajax({
            type: 'GET',
            url: tab.dataset['url']
          })
        }
        link.classList.add("active");
      } else {
        tab.classList.add("hidden");
        link.classList.remove("active");
      }
    }
  }

}
